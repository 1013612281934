
import { defineComponent, ref } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import { Actions } from "@/store/enums/StoreEnums";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { useToast } from "vue-toastification";
import * as Yup from "yup";

export default defineComponent({
  name: "sign-in",
  components: {
    Field,
    Form,
    ErrorMessage,
  },
  setup() {
    const store = useStore();
    const router = useRouter();

    const submitButton = ref<HTMLElement | null>(null);

    //Create form validation object
    const login = Yup.object().shape({
      email: Yup.string().email().required().label("Email"),
      password: Yup.string().min(4).required().label("Password"),
    });

    //Form submit function
    const onSubmitLogin = (values) => {
      // Clear existing errors
      store.dispatch(Actions.LOGOUT);

      if (submitButton.value) {
        // Activate indicator
        submitButton.value.setAttribute("data-kt-indicator", "on");
      }
      
      // Send login request
      store
        .dispatch(Actions.LOGIN, values)
        .then((res) => {
          console.log(res)
          // Go to page after successfully login
          useToast().success('Welcome back..!')
          router.push({ name: "dashboard" });
          //Deactivate indicator
          submitButton.value?.removeAttribute("data-kt-indicator");
        })
        .catch((err) => {
          console.log(err.message)
          useToast().error(store.getters.getErrors);
          //Deactivate indicator
          submitButton.value?.removeAttribute("data-kt-indicator");
        });
    };

    return {
      onSubmitLogin,
      login,
      submitButton,
    };
  },
});
